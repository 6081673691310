import React from "react"
import "../../css/components/blur.scss"
import PropTypes from "prop-types";

const Blur = props =>
    <div className={"blur"}
         style={{
             backgroundImage: `url(${props.background})`,
             filter: 'blur(' + props.blur + 'px)',
         }}/>


Blur.defaultProps = {
    blur: 2,
}

Blur.propTypes = {
    background: PropTypes.string,
    blur: PropTypes.number,
}

export default Blur
