import React from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import Img from "gatsby-image"

import "../../css/containers/index/certifications.scss"
import Section from "../../components/section"
import ScrollDown from "../../components/Buttons/scrollDown"
import YellowButton from "../../components/Buttons/yellowButton"


const Certifications = () => {
    const data = useStaticQuery(graphql`
    query {
      ecoArtisan: file(relativePath: { eq: "eco-artisan.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cee: file(relativePath: { eq: "cee.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    return (
        <Section
            id="certifications"
            title="Nos certifications"
            description={<p>
                Athies Batiment est certifié <b>RGE</b> : nous possédons le label <a href="https://www.eco-artisan.net/">ECO artisan</a>. Nous vous informons aussi qu'il existe différentes aides pour réaliser vos travaux. Par exemple, le dispositif <a href="https://www.fournisseur-energie.com/actualites/certificats-economies-energie/">CEE</a> est une aide de l'Etat qui peut prendre en charge environ 40% du montant total de vos travaux réalisés avec des matériaux écologiques. Grâce aux différentes aides, Athies Batiment peut réaliser votre <b>isolation pour 1€</b>. Pour en savoir plus, visitez notre page <Link to={"certifications"}>Certifications</Link>.
            </p>}>
                <>
                    <div className={"images"}>
                        <Img fluid={data.ecoArtisan.childImageSharp.fluid} alt={"L'entreprise Athies Batiment dans l'Aisne est certifiée éco-artisan"} className={"ecoArtisan"}/>
                        <Img fluid={data.cee.childImageSharp.fluid} alt={"L'entreprise Athies Batiment dans l'Aisne possède le Certificat d'Economie d'Energie"} className={"cee"}/>
                    </div>
                    <YellowButton link={"certifications"}>En savoir plus</YellowButton>
                    <ScrollDown link={"contact"}/>
                </>
        </Section>
    )
}

export default Certifications
