import React from "react"
import { FaChevronCircleDown } from 'react-icons/fa'
import PropTypes from "prop-types"
import { Link } from 'react-scroll'

import "../../css/components/buttons.scss"

const ScrollDown = props => {
    return (
        <Link className={"scrollDown"} activeClass="active" to={props.link} spy={true} smooth={true} offset={0} duration={1000}>
            <FaChevronCircleDown/>
        </Link>
    )
}

ScrollDown.defaultProps = {
    link: '',
}

ScrollDown.propTypes = {
    link: PropTypes.string.isRequired,
}

export default ScrollDown
