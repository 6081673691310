import React from "react"
import YellowButton from "../Buttons/yellowButton";
import "../../css/components/card.scss"
import Blur from "../Blur/blur";
import PropTypes from "prop-types";

const Card = props => {
    return (
        <div className={"card"}>
            <Blur background={props.background} blur={props.blur}/>
            <div className={"content"}>
                <h2 className={"title"}>{props.title}</h2>
                {props.showButton ? <YellowButton link={props.buttonLink}>{props.buttonText}</YellowButton> : ''}
            </div>
        </div>
    )
}

Card.defaultProps = {
    background: '',
    title: '',
    showButton: true,
    buttonLink: '#',
    buttonText: 'Voir +'
}

Card.propTypes = {
    background: PropTypes.string,
    title: PropTypes.string,
    showButton: PropTypes.bool,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    blur: PropTypes.number,
}

export default Card
