import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from 'react-scroll'

import "../../css/containers/index/home.scss"
import Maison3 from "../../images/maison3.jpg"
import ScrollDown from "../../components/Buttons/scrollDown"
import YellowButton from "../../components/Buttons/yellowButton"
import Blur from "../../components/Blur/blur"


const Home = () => {
    const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo+eco-artisan.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    return (
        <section id="home">
            <Blur background={Maison3} blur={0}/>

            <div className={"content"}/>

            <div className={"home"}>
                <Img className={"logo"} alt="Logo d'Athies Batiment certifié RGE" fluid={data.logo.childImageSharp.fluid} />
                {/*<h1 className="title">Couverture - Maçonnerie - Menuiserie</h1>*/}
                <Link activeClass="active" to={"contact"} spy={true} smooth={true} offset={0} duration={1000}>
                    <YellowButton>Contactez-nous</YellowButton>
                </Link>

                <ScrollDown link={"services"}/>
            </div>
        </section>
    )
}

export default Home
