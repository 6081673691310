import React from "react"
import {Button} from "react-bootstrap";
import "../../css/components/buttons.scss"
import PropTypes from "prop-types";

const YellowButton = props =>
    props.link !== null && props.link !== undefined ?
        <Button href={props.link} className={"btn-yellow " + props.className}>{props.children}</Button>
    :
        <Button className={"btn-yellow " + props.className}>{props.children}</Button>

YellowButton.defaultProps = {
    className: '',
}

YellowButton.propTypes = {
    link: PropTypes.string,
    className: PropTypes.string,
}

export default YellowButton
