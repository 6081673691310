import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Home from "../containers/index/home"
import Services from "../containers/index/services"
import Certifications from "../containers/index/certifications"
import TrucksBanner from "../containers/index/trucksBanner"
import Contact from "../containers/index/contact"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Home/>
    <Services/>
    <TrucksBanner/>
    <Certifications/>
    <Contact/>
  </Layout>
)

export default IndexPage
