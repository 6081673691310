import React from "react"

import "../../css/containers/index/services.scss"
import Couverture from "../../images/couverture.jpg"
import Maconnerie from "../../images/maconnerie.jpg"
import Renovation from "../../images/renovation.jpg"
import Isolation from "../../images/isolation.jpg"
import Peinture from "../../images/peinture.jpg"
import CouvertureImg from "../../images/card.jpg"
import Section from "../../components/section"
import Card from "../../components/Card/card"
import ScrollDown from "../../components/Buttons/scrollDown"
import {Link} from "gatsby";

const Services = () => {
    return (
        <Section
            id="services"
            title="Nos services"
            description={
                <p>Athies Batiment vous propose ses services pour réaliser vos <b>travaux de maçonnerie générale et gros oeuvre de bâtiment dans le secteur de Laon</b>. Que se soit des travaux de couverture, de maçonnerie, de menuiserie ou d'isolation et de peinture, nos artisans qualifiés réaliseront vos travaux selon vos besoins. Nous intervenons principalement dans l'Aisne (02). Nous pouvons aussi réaliser votre isolation pour 1€, si vous souhaitez avoir plus d'informations, visitez notre page <Link to={"certifications"}>Certifications</Link>.
                </p>}>
            <>
                <div className={"row"}>
                    <div className={"card-service col-sm-12 col-lg-6 col-xl-4"}>
                        <Card background={Couverture} title={"Couverture"} buttonLink={"couverture"} blur={1}/>
                    </div>
                    <div className={"card-service col-sm-12 col-lg-6 col-xl-4"}>
                        <Card background={Maconnerie} title={"Maçonnerie"} buttonLink={"maconnerie"} blur={1}/>
                    </div>
                    <div className={"card-service col-sm-12 col-lg-6 col-xl-4"}>
                        <Card background={CouvertureImg} title={"Menuiserie"} buttonLink={"menuiserie"} blur={1}/>
                    </div>
                    <div className={"card-service col-sm-12 col-lg-6 col-xl-4"}>
                        <Card background={Renovation} title={"Rénovation"} buttonLink={"renovation"} blur={1}/>
                    </div>
                    <div className={"card-service col-sm-12 col-lg-6 col-xl-4"}>
                        <Card background={Isolation} title={"Isolation"} buttonLink={"isolation"} blur={1}/>
                    </div>
                    <div className={"card-service col-sm-12 col-lg-6 col-xl-4"}>
                        <Card background={Peinture} title={"Peinture - Plâtrerie "} buttonLink={"peinture-platerie"} blur={1}/>
                    </div>
                </div>
                <ScrollDown link={"certifications"}/>
            </>
        </Section>
    )
}

export default Services
