import React from "react"

import "../../css/containers/index/trucksBanner.scss"
import Trucks from "../../images/ab-camions.jpg"
import Blur from "../../components/Blur/blur"

const TrucksBanner = () => {
    return (
        <section id="trucksBanner">
            <Blur background={Trucks} blur={1}/>

            <div className={"content"}>
                <h2 className={"title"}>Athies Batiment, un savoir faire local à Laon dans l'aisne</h2>
            </div>
        </section>
    )
}

export default TrucksBanner
